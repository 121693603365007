export const theme = {
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 960,
			lg: 1536,
			xl: 1920
		}
	},
	palette: {
		primary: {
			light: "#6FC26B",
			main: "#4BB347",
			dark: "#347D31",
			contrastTest: "#FFFFFF"
		},
		secondary: {
			light: "#6FC26B",
			main: "#4BB347",
			dark: "#347D31",
			contrastTest: "#FFFFFF"
		},
		error: {
			light: "#B5444D",
			main: "#A31621",
			dark: "#720F17"
		},
		warning: {
			light: "#FFB759",
			main: "#FFA630",
			dark: "#B27421"
		},
		info: {
			light: "#378FC8",
			main: "#0674BB",
			dark: "#045182"
		},
		grey: {
			50: "#FAFAFA",
			100: "#F2F2F3",
			200: "#C9C9CF",
			300: "#AFAFB6",
			400: "#94949E",
			500: "#797986",
			600: "#61616B",
			700: "#494950",
			800: "#303036",
			900: "#242428",
			A100: "rgba(0, 0, 0, 0.1)",
			A200: "rgba(0, 0, 0, 0.2)",
			A400: "rgba(0, 0, 0, 0.4)",
			A700: "rgba(0, 0, 0, 0.7)"
		},
		background: {
			default: "#FFFFFF",
			header: "#21262D",
			footer: "#21262D",
			light: "#F8F8F8",
			medium: "#2A3038",
			dark: "#21262D"
		},
		text: {
			primary: "#21262D",
			secondary: "#666666"
		},
		divider: "rgba(255, 255, 255, 0.5)",
		contrastThreshold: 0
	},
	typography: {
		fontWeightBlack: 900,
		"display-large": {
			fontFamily: "Montserrat",
			fontSize: "clamp(40px, 5vw, 58px)",
			fontWeight: 900,
			hyphens: "auto",
			lineHeight: 1.2,
			wordBreak: "break-word"
		},
		"display-medium": {
			fontFamily: "Montserrat",
			fontSize: "clamp(32px, 5vw, 40px)",
			fontWeight: 700,
			hyphens: "auto",
			lineHeight: 1.2,
			wordBreak: "break-word"
		},
		"display-small": {
			fontFamily: "Montserrat",
			fontSize: "clamp(24px, 4vw, 32px)",
			fontWeight: 700,
			hyphens: "auto",
			lineHeight: 1.2,
			wordBreak: "break-word"
		},
		"headline-large": {
			fontFamily: "Montserrat",
			fontSize: "clamp(22px, 3vw, 28px)",
			fontWeight: 700,
			hyphens: "auto",
			lineHeight: 1.2,
			wordBreak: "break-word"
		},
		"headline-medium": {
			fontFamily: "Montserrat",
			fontSize: "clamp(20px, 3vw, 26px)",
			fontWeight: 700,
			hyphens: "auto",
			lineHeight: 1.2,
			wordBreak: "break-word"
		},
		"headline-small": {
			fontFamily: "Montserrat",
			fontSize: 24,
			fontWeight: 700,
			hyphens: "auto",
			lineHeight: 1.2,
			wordBreak: "break-word"
		},
		"title-large": {
			fontFamily: "Montserrat",
			fontSize: 22,
			fontWeight: 700,
			hyphens: "auto",
			lineHeight: 1.2,
			wordBreak: "break-word"
		},
		"title-medium": {
			fontFamily: "Montserrat",
			fontSize: 20,
			fontWeight: 700,
			hyphens: "auto",
			lineHeight: 1.2,
			wordBreak: "break-word"
		},
		"title-small": {
			fontFamily: "Montserrat",
			fontSize: 18,
			fontWeight: 700,
			hyphens: "auto",
			lineHeight: 1.2,
			wordBreak: "break-word"
		},
		"body-large": {
			fontFamily: "Montserrat",
			fontSize: 20,
			fontWeight: 400,
			lineHeight: 1.45
		},
		"body-medium": {
			fontFamily: "Montserrat",
			fontSize: 18,
			fontWeight: 400,
			lineHeight: 1.5
		},
		"body-small": {
			fontFamily: "Montserrat",
			fontSize: 16,
			fontWeight: 400,
			lineHeight: 1.5
		},
		"label-large": {
			fontFamily: "Montserrat",
			fontSize: 20,
			fontWeight: 700,
			lineHeight: 1.4
		},
		"label-medium": {
			fontFamily: "Montserrat",
			fontSize: 16,
			fontWeight: 700,
			lineHeight: 1.4
		},
		"label-small": {
			fontFamily: "Montserrat",
			fontSize: 14,
			fontWeight: 400,
			lineHeight: 1.4,
			textTransform: "uppercase"
		},
		// ... old //
		h1: {
			color: "#4BB347",
			fontFamily: "Montserrat",
			fontSize: "clamp(40px, 5vw, 58px)",
			fontWeight: 900,
			lineHeight: 1.2
		},
		h2: {
			fontFamily: "Montserrat",
			fontSize: "clamp(32px, 5vw, 40px)",
			fontWeight: 700,
			lineHeight: 1.2
		},
		h3: {
			fontFamily: "Montserrat",
			fontSize: "clamp(24px, 4vw, 32px)",
			fontWeight: 700,
			lineHeight: 1.2
		},
		h4: {
			fontFamily: "Montserrat",
			fontSize: "clamp(22px, 3vw, 28px)",
			fontWeight: 700,
			lineHeight: 1.2
		},
		h5: {
			fontFamily: "Montserrat",
			fontSize: "clamp(20px, 3vw, 26px)",
			fontWeight: 700,
			lineHeight: 1.2
		},
		h6: {
			fontFamily: "Montserrat",
			fontSize: 24,
			fontWeight: 700,
			lineHeight: 1.2
		},
		subtitle1: {
			fontFamily: "Montserrat",
			fontSize: 24,
			fontWeight: 700,
			lineHeight: 1.2
		},
		subtitle2: {
			fontFamily: "Montserrat",
			fontSize: 20,
			fontWeight: 700,
			lineHeight: 1.2
		},
		overline: {
			fontFamily: "Montserrat",
			fontSize: 14,
			fontWeight: 400,
			lineHeight: 1.4,
			textTransform: "uppercase"
		},
		body1: {
			fontFamily: "Montserrat",
			fontSize: 18,
			fontWeight: 400,
			lineHeight: 1.5
		},
		body2: {
			fontFamily: "Montserrat",
			fontSize: 16,
			fontWeight: 400,
			lineHeight: 1.5
		},
		caption: {
			fontFamily: "Montserrat",
			fontSize: 14,
			fontWeight: 400,
			lineHeight: 1.4,
			textTransform: "uppercase"
		},
		button: {
			fontFamily: "Montserrat",
			fontSize: 16,
			fontWeight: 700,
			lineHeight: 1,
			textTransform: "none"
		}
	},
	shape: {
		borderRadius: 4
	},
	overrides: {
		MuiButton: {
			root: {
				minHeight: 48,
				textTransform: "none"
			},
			sizeLarge: {
				fontSize: 18,
				minHeight: 54
			},
			sizeSmall: {
				fontSize: 14,
				minHeight: 40
			}
		}
	},
	props: {
		MuiButton: {
			disableElevation: true
		},
		MuiButtonGroup: {
			disableElevation: true
		}
	}
};
